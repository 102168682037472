import React, { useCallback, useEffect, useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import H2C from 'components/typography/h2'
import H2 from 'components/new/typography/h2'
import debounce from 'utils/debounce'
import formatPrice from 'utils/format-price'
import formatUsedEquipmentMeterType from 'utils/format-used-equipment-meter-type'
import ClickOut from 'components/click-out'
import CategorySquare from 'components/used-equipment/category-square'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import UnstyledButton from 'components/button/unstyled-button'
import { useEffectOnce } from 'hooks/use-effect-once'
import { useIsMounted } from 'hooks/use-is-mounted'
import AlternatingGrid from 'components/alternating-grid'
import AspectRatioContainer from 'components/aspect-ratio-container'
import Spacer from 'components/new/spacer'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'components/button/button'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import PromotionsGrid from 'components/new/promotions-grid'
import PromotionBanner from 'components/new/promotion-banner'
import Disclaimers from 'components/new/disclaimers'
import { useBetweenDates } from 'hooks/use-between-dates'

const formatDisclaimers = disclaimers => {
  return disclaimers.map((disclaimer, index) => (
    <p key={index}>
      ({index + 1}) {disclaimer}
    </p>
  ))
}

const BargainBin = () => {
  return (
    <BargainBinContainer>
      <strong>Bargain Bin</strong>
      <span>View our hand-picked list of equipment at great prices!</span>
      <Link to='/used-equipment/bargain-bin/'>Browse equipment ›</Link>
    </BargainBinContainer>
  )
}

const BargainBinContainer = styled.div`
  align-items: flex-start;
  background-color: ${props => props.theme.color.n20};
  color: ${props => props.theme.color.n700};
  display: flex;
  flex-direction: column;
  font-size: 1.15em;
  justify-content: flex-start;
  margin: 16px 0 24px;
  padding: 12px;
  strong {
    background-color: ${props => props.theme.color.g400};
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    padding: 4px 6px;
    text-transform: uppercase;
  }
  span {
    padding: 12px 0;
  }
  a {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;
    white-space: nowrap;
    :hover,
    :focus {
      color: ${props => props.theme.color.n700};
      text-decoration: none;
    }
  }
  @media (min-width: 600px) {
    align-items: center;
    flex-direction: row;
    font-size: 1em;
    justify-content: space-between;
    padding: 8px 12px 8px 8px;
    span {
      flex-grow: 2;
      padding: 0 8px 0 0;
    }
  }
`
const placeholderSuggestions = [
  'combines',
  'row crop tractors',
  'gators',
  'utility tractors',
  'zero turns',
]

const getPlaceholder = () => {
  const suggestion = Math.floor(Math.random() * placeholderSuggestions.length)
  return `Try searching for ${placeholderSuggestions[suggestion]}`
}

const suggestions = [
  'compact utility tractors',
  'lawn tractors',
  'zero turns',
  'gators',
  'utility tractors',
]

const UsedEquipmentPageTemplate = ({
  data: { auctionTimeLogo, allSanityPromotion, bargainBinEquipment },
  pageContext: { categories, equipmentTotal },
}) => {
  const [activeOffers, setActiveOffers] = useState()
  const [disclaimers, setDisclaimers] = useState()

  // HUT235
  const promotionActive = useBetweenDates({ end: new Date('2025-03-01T00:00:00-06:00') })

  useEffectOnce(() => {
    const disclaimers = []

    const activeOffers = allSanityPromotion.nodes
      .filter(offer => {
        const currentTimestamp = Date.now()
        const startDateTimestamp = new Date(offer.startDate).getTime()
        const endDateTimestamp = new Date(offer.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day

        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
      .map(offer => {
        const disclaimerMatchIndex = disclaimers.findIndex(val => val === offer.disclaimer)

        if (disclaimerMatchIndex === -1) {
          disclaimers.push(offer.disclaimer)
        }

        return {
          ...offer,
          disclaimerNumber:
            disclaimerMatchIndex === -1 ? disclaimers.length : disclaimerMatchIndex + 1,
        }
      })

    setActiveOffers(Array.isArray(activeOffers) && activeOffers.length > 0 ? activeOffers : null)
    setDisclaimers(disclaimers.length > 0 ? disclaimers : null)
  })

  const hasOffers = Array.isArray(activeOffers) && activeOffers.length > 0
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Used Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Find used equipment for sale at a Hutson store near you. Browse through our large selection of the most popular brands offered at the best prices.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Used Equipment',
                'item': 'https://www.hutsoninc.com/used-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Content>
        {promotionActive ? (
          <PromotionBanner
            label='Offer'
            text='0% for 60 or lease option on prior year skid steers and track loaders'
            link='/lp/b8c67b1b-8db9-4d9e-a004-72d93577235d'
            linkText='View Offer'
          />
        ) : null}
        {bargainBinEquipment.totalCount !== 0 ? (
          <React.Fragment>
            <BargainBin />
            <Spacer size='xl' />
          </React.Fragment>
        ) : null}
        <StyledH2C type='h1' textAlign='center'>
          What are you looking for?
        </StyledH2C>
        <Search />
        <FindByCategory categories={categories} equipmentTotal={equipmentTotal} />
        {hasOffers ? (
          <React.Fragment>
            <Spacer />
            <H2>Current Promotions</H2>
            <Spacer size='s' />
            <Promotions offers={activeOffers} />
            <Spacer />
            <Disclaimers>{formatDisclaimers(disclaimers)}</Disclaimers>
          </React.Fragment>
        ) : null}
      </Content>

      <LinksGrid
        direction='ltr'
        content={[
          <AuctionTimeLogoContainer>
            <AuctionTimeLogo image={auctionTimeLogo.childImageSharp.gatsbyImageData} alt='' />
          </AuctionTimeLogoContainer>,
          <LinksGridText>
            <H2>View our AuctionTime listings</H2>
            <Spacer size='m' variable />
            <Button
              as={ButtonA}
              href='https://www.auctiontime.com/listings/auctions/online/all-auctions/hutson?crmid=61126'
              ghost
              color='green'
            >
              View listings
            </Button>
          </LinksGridText>,
        ]}
      />
    </Layout>
  )
}

const LinksGrid = styled(AlternatingGrid)`
  background-color: ${props => props.theme.color.n10};
`

const AuctionTimeLogoContainer = styled(AspectRatioContainer)`
  background-color: ${props => props.theme.color.n20};
`

const AuctionTimeLogo = styled(GatsbyImage)`
  left: 50%;
  max-width: 480px;
  top: 50%;
  transform: translate(-50%, -50%);
`

const LinksGridText = styled.div`
  direction: ltr;
  padding: 24px;

  @media (min-width: 900px) {
    padding: 24px 48px;
  }
`

const ButtonA = styled(OutboundLink)`
  display: inline-block;
  text-decoration: none;
`

const Content = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
  width: 85%;

  @media (min-width: 1024px) {
    padding: 80px 25px;
    width: 1024px;
  }
`

const StyledH2C = styled(H2C)`
  font-size: 1.8em;

  hr {
    width: 80px;
  }
`

let id = 0

const Search = () => {
  const isMounted = useIsMounted()
  const [query, setQuery] = useState('')
  const [, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const [placeholder, setPlaceholder] = useState('Try searching for zero turns')
  const [pageResults, setPageResults] = useState([])
  const [equipmentResults, setEquipmentResults] = useState([])
  const [clickedOut, setClickedOut] = useState(true)
  const [focusing, setFocusing] = useState(false)

  useEffectOnce(() => {
    setPlaceholder(getPlaceholder())
  })

  useEffect(() => {
    if (clickedOut && focusing) {
      setClickedOut(false)
    }
  }, [clickedOut, focusing])

  const handleChange = e => {
    const val = e.target.value
    if (val !== query) {
      setQuery(val)
    }
    if (!!val) {
      search(val)
    } else {
      setPlaceholder(getPlaceholder())
      setActive(false)
      setPageResults([])
      setEquipmentResults([])
    }
  }

  const search = useCallback(
    debounce(async val => {
      const results = {
        pages: [],
        equipment: [],
      }

      setLoading(true)

      id += 1
      const currentId = id

      const searchResults = await fetch('/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ query: val }),
      }).then(res => res.json())

      const filteredSearchResults = searchResults.filter(obj => /used/i.test(obj.tag))

      for (let i = 0; i < filteredSearchResults.length; i++) {
        const currentResult = filteredSearchResults[i]
        const resultType = /page/i.test(currentResult.tag) ? 'page' : 'equipment'
        if (results.equipment.length === 3 && results.pages.length === 3) {
          break
        }
        if (results.equipment.length < 3 && resultType === 'equipment') {
          results.equipment.push(currentResult)
        } else if (results.pages.length < 3 && resultType === 'page') {
          results.pages.push(currentResult)
        }
      }

      if (currentId === id && isMounted()) {
        setPageResults(results.pages)
        setEquipmentResults(results.equipment)
        setLoading(false)
        setClickedOut(false)
        setActive(true)

        gtag('event', gtagEvents.search, {
          search_term: val.trim().toLowerCase(),
        })

        fbq('track', fbqEvents.Search, {
          search_string: val.trim().toLowerCase(),
        })
      }
    }, 400),
    []
  )

  const populateSearch = val => {
    setQuery(val)
    setClickedOut(false)
    search(val)
  }

  const onFocus = () => {
    setFocusing(true)
    setClickedOut(false)
  }

  const onBlur = () => {
    setFocusing(false)
  }

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      setFocusing(false)
      setClickedOut(false)
      e.currentTarget.blur()
    }
  }

  const handleClickOut = () => {
    if (!focusing) {
      setClickedOut(true)
    }
  }

  const doNavigate = e => {
    e.preventDefault()
    navigate(e.currentTarget.pathname)
  }

  let resultsShowing = false
  let showingNoResults = false
  if (focusing || !clickedOut) {
    if (pageResults && pageResults.length > 0) {
      resultsShowing = true
    } else if (equipmentResults && equipmentResults.length > 0) {
      resultsShowing = true
    } else if (active && query !== '') {
      showingNoResults = true
    }
  }
  return (
    <SearchContainer>
      <SearchInput
        type='search'
        className={!clickedOut || query ? 'filled' : ''}
        clickedOut={clickedOut}
        value={query}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
      />
      {showingNoResults && <NoResults>No results found matching your search.</NoResults>}
      <ClickOut onClickOut={handleClickOut}>
        {resultsShowing && (
          <SearchResultsContainer>
            {pageResults && pageResults.length > 0 && (
              <>
                <SearchResultsHeader>Pages</SearchResultsHeader>
                {pageResults.map(page => (
                  <SearchResult key={page.slug}>
                    <Link to={page.slug} onMouseUp={doNavigate}>
                      <ResultTitle>{page.title}</ResultTitle>
                      <ResultBreadcrumbs {...page.data} />
                    </Link>
                  </SearchResult>
                ))}
              </>
            )}
            {equipmentResults && equipmentResults.length > 0 && (
              <>
                <SearchResultsHeader>
                  Equipment
                  <HeaderViewAll>
                    <Link
                      to={`/used-equipment/all/?search=${encodeURIComponent(query)}`}
                      onMouseUp={doNavigate}
                    >
                      View all equipment results
                    </Link>
                  </HeaderViewAll>
                </SearchResultsHeader>
                {equipmentResults.map(equipment => (
                  <SearchResult key={equipment.slug}>
                    <Link to={equipment.slug} onMouseUp={doNavigate}>
                      <EquipmentResult {...equipment} />
                    </Link>
                  </SearchResult>
                ))}
                <ViewAll>
                  <Link
                    to={`/used-equipment/all/?search=${encodeURIComponent(query)}`}
                    onMouseUp={doNavigate}
                  >
                    View all results for {query}
                  </Link>
                </ViewAll>
              </>
            )}
          </SearchResultsContainer>
        )}
      </ClickOut>
      <SuggestionsList populateSearch={populateSearch} />
    </SearchContainer>
  )
}

const SearchContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  position: relative;
  z-index: 7999;
`

const SearchInput = styled.input`
  font-size: 1.2em;
  height: auto;
  margin-top: 30px;
  padding: 15px;
`

const NoResults = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #b7b7b7;
  border-top: none;
  padding: 20px;
  position: absolute;
  width: 100%;
`

const SearchResultsContainer = styled.div`
  background-color: #fff;
  border: 1px solid #b7b7b7;
  border-top: none;
  position: absolute;
  width: 100%;
`

const SearchResultsHeader = styled.div`
  border-bottom: 1px solid #e7e7e7;
  font-size: 0.95em;
  padding: 15px 20px 10px;
`

const SearchResult = styled.div`
  a {
    background-color: #fff;
    border-bottom: 1px solid #e7e7e7;
    color: #000;
    display: block;
    padding: 20px;
    text-decoration: none;

    :hover,
    :focus {
      background-color: #f7f7f7;
    }
  }
`

const HeaderViewAll = styled.div`
  display: block;
  float: right;

  a {
    color: #377539;
  }
`

const ViewAll = styled.div`
  background-color: #e7e7e7;
  padding: 10px 20px;

  a {
    color: #377539;
  }
`

const ResultBreadcrumbs = ({ category, manufacturer, model, subcategory }) => {
  const breadcrumbs = ['Used Equipment', category, subcategory, manufacturer, model].filter(x => x)
  return (
    <ResultBreadcrumbsList>
      {breadcrumbs.map(str => (
        <ResultBreadcrumbsListItem key={str}>{str}</ResultBreadcrumbsListItem>
      ))}
    </ResultBreadcrumbsList>
  )
}

ResultBreadcrumbs.propTypes = {
  category: PropTypes.string,
  manufacturer: PropTypes.string,
  model: PropTypes.string,
  subcategory: PropTypes.string,
}

const ResultBreadcrumbsList = styled.ul`
  margin: 0;
  padding: 0;
`

const ResultBreadcrumbsListItem = styled.li`
  display: inline-block;
  font-size: 0.85em;
  list-style: none;
  margin: 0;
  padding: 0;

  :not(:last-of-type):after {
    content: '>';
    display: inline-block;
    margin: 0 5px;
  }
`

const EquipmentResult = ({
  data: { horsepower, location, meters, price, serialNumber, stockNumber },
  image,
  title,
}) => {
  return (
    <>
      <EquipmentResultImage image={image} />
      <EquipmentResultCopy>
        <ResultTitle>{title}</ResultTitle>
        {stockNumber || serialNumber ? (
          <div>
            {stockNumber ? <EquipmentResultId>Stock #{stockNumber}</EquipmentResultId> : null}
            {serialNumber ? <EquipmentResultId>Serial #{serialNumber}</EquipmentResultId> : null}
          </div>
        ) : null}
        <EquipmentResultPrice>{formatPrice(price, { round: true })}</EquipmentResultPrice>
        {horsepower && <EquipmentResultDetail>{horsepower} Engine HP</EquipmentResultDetail>}
        {Array.isArray(meters)
          ? meters.map(meter => (
              <EquipmentResultDetail key={meter.type}>
                {meter.value.toLocaleString('en-US')}{' '}
                {formatUsedEquipmentMeterType(meter.type, { titleCase: true })}
              </EquipmentResultDetail>
            ))
          : null}
        <EquipmentResultDetail>{location}</EquipmentResultDetail>
      </EquipmentResultCopy>
    </>
  )
}

EquipmentResult.propTypes = {
  data: PropTypes.shape({
    horsepower: PropTypes.number,
    location: PropTypes.string.isRequired,
    meters: PropTypes.arrayOf(
      PropTypes.shape({ type: PropTypes.string.isRequired, value: PropTypes.number.isRequired })
    ),
    price: PropTypes.number.isRequired,
  }),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const EquipmentResultImage = styled.img`
  background-color: #fafafa;
  background-image: url('${props => props.image}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100px;
  max-height: 100%;
  margin: 0 0 15px;
  position: relative;
  width: 150px;

  @media (min-width: 540px) {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    width: 25%;
  }
`

const EquipmentResultId = styled.span`
  color: #2a2a2a;
  display: inline-block;
  font-size: 0.9em;

  :not(:last-child) {
    margin: 0 12px 6px 0;
  }
`

const EquipmentResultCopy = styled.div`
  display: block;
  padding: 0;

  @media (min-width: 540px) {
    box-sizing: border-box;
    display: inline-block;
    padding: 0 20px;
    vertical-align: middle;
    width: 75%;
  }
`

const ResultTitle = styled.h4`
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 2px;
  text-align: left;
  text-transform: none;
`

const EquipmentResultPrice = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  margin: 6px 0 10px;
`

const EquipmentResultDetail = styled.div`
  background-color: #e7e7e7;
  border-radius: 1px;
  color: #2a2a2a;
  display: inline-block;
  font-size: 0.85em;
  margin: 0 6px 6px 0;
  padding: 4px 8px;
`

const SuggestionsList = ({ populateSearch }) => {
  return (
    <StyledSuggestionsList aria-label='Search suggestions'>
      {suggestions.map(suggestion => (
        <li key={`SuggestionsListItem_${suggestion}`}>
          <Suggestion
            aria-label={`Search for ${suggestion}`}
            onClick={() => populateSearch(suggestion)}
          >
            {suggestion}
          </Suggestion>
        </li>
      ))}
    </StyledSuggestionsList>
  )
}

SuggestionsList.propTypes = {
  populateSearch: PropTypes.func.isRequired,
}

const StyledSuggestionsList = styled.ul`
  margin: 15px 0 0;
  padding: 0;

  li {
    display: inline-block;
    list-style: none;
    margin: 0 10px 10px 0;
    padding: 0;
  }
`

const Suggestion = styled(UnstyledButton)`
  background-color: #efefef;
  color: #2a2a2a;
  cursor: pointer;
  font-size: 0.9em;
  padding: 4px 8px;

  :hover,
  :focus {
    background-color: #dfdfdf;
  }
`

const FindByCategory = ({ categories, equipmentTotal }) => {
  return (
    <FindByCategoryContainer>
      <StyledH2C textAlign='center'>Browse by category</StyledH2C>
      <CategoriesContainer>
        {Object.keys(categories).map(category => (
          <StyledCategorySquare
            category={category}
            subcategory={categories[category].subcategories}
            total={categories[category].total}
            key={category}
          />
        ))}
        <StyledCategorySquare category='All Used Equipment' total={equipmentTotal} />
      </CategoriesContainer>
    </FindByCategoryContainer>
  )
}

FindByCategory.propTypes = {
  categories: PropTypes.objectOf(
    PropTypes.shape({
      subcategories: PropTypes.arrayOf(
        PropTypes.shape({
          subcategory: PropTypes.string.isRequired,
          total: PropTypes.number.isRequired,
        })
      ).isRequired,
      total: PropTypes.number.isRequired,
    })
  ).isRequired,
  equipmentTotal: PropTypes.number.isRequired,
}

const FindByCategoryContainer = styled.div`
  :not(:first-child) {
    margin-top: 60px;
  }
`

const CategoriesContainer = styled.div`
  @media (min-width: 480px) {
    ${clearfix}
  }
`

const StyledCategorySquare = styled(CategorySquare)`
  margin-bottom: 45px;

  @media (min-width: 480px) {
    ${column('1/2')}
    vertical-align: top;
  }

  @media (min-width: 800px) {
    ${column('1/4')}
  }
`

const Promotions = ({ offers }) => {
  const [expanded, setExpanded] = useState(false)
  const topOffers = offers.slice(0, 3)
  const otherOffers = offers.slice(3)
  return (
    <React.Fragment>
      <PromotionsGrid
        promotions={[...topOffers, ...(expanded ? otherOffers : [])]}
        showSeeMoreCard={offers.length < 3}
        id='offers-grid'
      />
      {offers.length > 3 ? (
        <Button
          onClick={() => setExpanded(!expanded)}
          color='green'
          ghost
          aria-expanded={expanded}
          aria-controls='offers-grid'
        >
          {expanded ? <>Show fewer offers</> : <>Show more offers</>}
        </Button>
      ) : null}
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  {
    auctionTimeLogo: file(relativePath: { eq: "auctiontime-logo.png" }) {
      ...SharpImage480
    }
    allSanityPromotion(
      filter: {
        websiteEnabled: { eq: true }
        type: { in: ["financing", "discount", "financing-discount", "cash-purchase-discount"] }
        isUsedEquipmentPromotion: { eq: true }
      }
      sort: { order: ASC, fields: [details___term, details___rate, details___discount] }
    ) {
      nodes {
        disclaimer
        endDate
        id
        link: slug
        startDate
        subtitle
        title
        type
      }
    }
    bargainBinEquipment: allMachineFinderEquipment(
      filter: { inBargainBin: { eq: true } }
      sort: { fields: [modifiedDate], order: DESC }
    ) {
      totalCount
    }
  }
`

export default UsedEquipmentPageTemplate
